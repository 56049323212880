import AppStore from "stores/app";
import TreeStore from "stores/tree";
import AppApi from "apis/app";
import ITreeNode from "types/treeNode";

/*

export const brickDirectory = () => {
    const url = '/brick_dir';
    return {
        [+] get: () => axios.get(url),
        [+] create: (toCreate) => axios.post(`${url}/node`, toCreate),
        [+] update: ({ id, toUpdate }) => axios.patch(`${url}/node/${id}`, toUpdate),
        [+] delete: (nodeIds) => axios.delete(`${url}/nodes`, { params: { nodeIds } }),
        // getOne: ({ id }) => axios.get(`${url}/${id}`),
        // delete: ({ id }) =>  axios.delete(`${url}/${id}`)
    };
};

*/
export default class TreeApi {

    constructor(
        private api: AppApi,
        private store: AppStore,
        private treeStore: TreeStore,
        private readonly url: string) { }

    async getAll() {
        const res = await this.api.client.get(this.url);
        this.treeStore.load(res.data.map((treeNodeJson: any) => treeNodeJson as ITreeNode));
    }

    async createEntry(nodeJson: any): Promise<ITreeNode | null> {
        // let node: ITreeNode | null = null;
        // try {
        //     const res = await this.api.client.post(`${this.url}/node`, nodeJson);
        //     node = res.data.node as ITreeNode;
        //     this.treeStore.add(node);
        // }
        // catch { }
        const nodes = await this.createEntries(nodeJson.parent_id, [nodeJson]);
        console.log('node', nodes && nodes[0]);
        return nodes && nodes[0];
    }

    async createEntries(parentId: string, nodeJsons: any[]): Promise<ITreeNode[] | null> {
        let nodes: ITreeNode[] | null = null;
        try {
            const res = await this.api.client.post(`${this.url}/nodes`, { parentId, entries: nodeJsons });
            nodes = res.data.nodes as ITreeNode[];
            this.treeStore.add(nodes);
        }
        catch { }
        console.log('nodes', nodes);
        return nodes;
    }

    async update(id: string, nodeJson: any): Promise<ITreeNode | null> {
        let node: ITreeNode | null = null;
        try {
            const res = await this.api.client.patch(`${this.url}/node/${id}`, nodeJson);
            node = res.data as ITreeNode;
            this.treeStore.update(node);
        }
        catch { }
        console.log('node', node);
        return node;
    }

    async moveNodes(nodeIds: string[], targetId: string, position: number) {
        console.log('moveNodes', nodeIds, targetId, position);
        let movedNodes: ITreeNode[] | [] = [];
        let targetNode: ITreeNode | null = null;
        try {
            const res = await this.api.client.patch(`${this.url}/nodes`, { nodeIds, targetId, position });
            movedNodes = res.data.nodes as ITreeNode[];
            targetNode = res.data.targetNode as ITreeNode;
            const parentIds = res.data.parentIds as string[];
            this.treeStore.moveNodes(movedNodes, targetNode, parentIds);
        }
        catch {
            console.error('TreeApi::moveNodes');
        }
        console.log('moveNodes', movedNodes);
        console.log('targetNode', targetNode);
        return movedNodes;
    }

    async delete(nodeIds: string[]) {
        let deletedNodes: ITreeNode[] | [] = [];
        try {
            const res = await this.api.client.delete(`${this.url}/nodes`, { params: { nodeIds } });
            deletedNodes = res.data.nodes as ITreeNode[];
            this.treeStore.delete(nodeIds);
        }
        catch { }
        console.log('deletedNodes', deletedNodes);
        return deletedNodes;
    }
}