import React, { useState, useEffect } from 'react';

import { TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PROP_ROW = Object.freeze({
    CAPTION: {
        ID: 'caption',
        Text: 'Caption'
    },
    TYPE: {
        ID: 'type',
        Text: 'Type'
    },
    SELECTOR: {
        ID: 'selector',
        Text: 'Selector'
    },
});

export const GridPropetryEditor = ({ blockData, onChange = () => { } }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (rows.length === 0) return;
        console.log('rows', rows);
        const newName = rows.find(row => row.id === PROP_ROW.CAPTION.ID)?.value;
        const newType = rows.find(row => row.id === PROP_ROW.TYPE.ID)?.value;
        const newID = rows.find(row => row.id === PROP_ROW.SELECTOR.ID)?.value;
        onChange({ ...blockData, name: newName, type: newType, ID: newID });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);

    useEffect(() => {
        if (blockData === null) {
            setRows([]);
            return;
        }
        console.log('blockData', blockData);
        let blockDataRows = [];
        // caption
        blockDataRows.push({
            id: PROP_ROW.CAPTION.ID,
            name: PROP_ROW.CAPTION.Text,
            type: 'text',
            value: blockData.name
        });
        // type`
        blockDataRows.push({
            id: PROP_ROW.TYPE.ID,
            name: PROP_ROW.TYPE.Text,
            type: 'select',
            value: blockData.type
        });
        // selector 
        blockDataRows.push({
            id: PROP_ROW.SELECTOR.ID,
            name: PROP_ROW.SELECTOR.Text,
            type: 'text',
            value: blockData.ID
        });
        console.log('blockDataRows', blockDataRows);
        setRows([...blockDataRows]);
    }, [blockData]);

    const onInputChange = React.useCallback((row, newValue) => {
        console.log('onInputChange', row, newValue);
        const newRows = rows.map(r => r.id === row.id ? { ...r, value: newValue } : r);
        setRows([...newRows]);
    }, [rows]);


    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: 100 }}>Name</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell>{row.name}</TableCell>
                            {row.type === 'text' ?
                                (
                                    <TableCell align="left">
                                        <TextField
                                            variant='standard'
                                            value={row.value}
                                            name={row.name}
                                            onChange={e => { console.log(e.target.value); onInputChange(row, e.target.value) }}
                                        />
                                    </TableCell>
                                ) :
                                (
                                    <TableCell>
                                        <Select
                                            variant="standard"
                                            value={row.value}
                                            onChange={e => { console.log(e); onInputChange(row, e.target.value) }}
                                        >
                                            {row.value === 'brick' && (<MenuItem value="brick"><em>Brick</em></MenuItem>)}
                                            {row.value !== 'brick' && (<MenuItem value="generic">Generic</MenuItem>)}
                                            {row.value !== 'brick' && (<MenuItem value="table">Table</MenuItem>)}
                                        </Select>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
