import { useState, useEffect, useCallback, useRef, memo } from 'react';
import { /*autorun,*/ reaction } from 'mobx';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import { useSnackbar } from 'notistack';


import { css } from 'aphrodite';
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import 'blockly_vmt/blocks/brick_factory_blocks';
import { createBlock, createBlockData, createField, createStatement, createControlBlock } from './blockly_stuff/xml';
import { useBFBlocklyController } from './blockly_stuff/useBFBlocklyController';
// VMT
import BlocklyComponent, { Block } from 'components/BlocklyComponent';
import GridPropetryEditor from './GridPropertyEditor';

import styles from 'components/Styles';
import { FloatingButton } from 'components/FloatingControls';
import DialogModal from 'components/Modals/DialogModal';
import { ModeStates } from 'utils/const';

import { useAppContext } from "app-context";

// reactive (RxJS)
import { brickId2EditStore, useStore } from 'stores';


/**
 * Name of block if not named.
 */
// const UNNAMED = 'unnamed';
const emptyInitialXml = '<xml/>';

export const BrickFactoryComponent = memo(() => {
    // console.log('<BrickFactoryComponent/>');

    const { api, store } = useAppContext();


    const [brickFactoryWorkspace, setBrickFactoryWorkspace] = useState(null);
    // const [labelPos, setLabelPos] = useState({ x: -100, y: -100 });
    const [originalBrick, setOriginalBrick] = useState(null);
    const [origWsXml, setOrigWsXml] = useState();
    const [brickMode, setBrickMode_] = useState({
        state: ModeStates.NEW_STATE,
        touched: false
    });
    const brickModeRef = useRef(brickMode);

    const setBrickMode = (state) => {
        console.log('setBrickMode', state, typeof state);
        brickModeRef.current = state;
        setBrickMode_(state);
    };

    // const [blocklyBFEvent, setBlocklyBFEvent] = useState(null);
    // const [selectedBlockData, setSelectedBlockData] = useState(null);
    // const blocklyController = useBFBlocklyController();
    const {
        blocklyChangeListener,
        blocklyEvent,
        selectedBlockData,
        setSelectedBlockData,
        getHandleResize,
        labelPos
    } = useBFBlocklyController();
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

    const [brickId2Edit] = useStore(brickId2EditStore);
    const brickId2EditRef = useRef(null);
    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    const loadBrickById = useCallback((vmtBrickId) => {
        Blockly.Events.disable();
        try {
            if (vmtBrickId !== null) {
                // const brickData = bricksData[vmtBrickId];
                // const brick = store.brick.byId.get(vmtBrickId);
                const brick = store.brick.byId(vmtBrickId);
                setOriginalBrick(brick);
                console.log('brickData', brick);
                if (brick === undefined) {
                    setOriginalBrick(null);
                    loadBrickById(null);
                    return;
                }
                //----------------------------------------------------------------
                // reconstruct Brick Factory worspace from the given brick
                // (Brick Editing Mode)
                //----------------------------------------------------------------
                // XML root node
                const initialXml = Blockly.utils.xml.createElement('xml');

                // add empty factory_base block
                let nextElement = createBlock('factory_base', false, false);

                const data = Blockly.utils.xml.createElement('data');
                data.appendChild(Blockly.utils.xml.createTextNode(
                    JSON.stringify(
                        createBlockData({
                            id: vmtBrickId,
                            name: brick.name,
                            anchor: '<brick_selector>',
                            type: 'brick'
                        }))));
                nextElement.appendChild(data);

                initialXml.appendChild(nextElement);
                // brick NAME
                nextElement.appendChild(createField('NAME', brick.name)); // <== brick name

                // if we have got controls
                // if (brickData.controls.length > 0) {
                // add input statement block
                nextElement = nextElement.appendChild(createStatement('INPUTS'));

                brick.controls.forEach((field) => {
                    console.log('Field', field);
                    nextElement = nextElement.appendChild(createControlBlock(field));
                    nextElement = nextElement.appendChild(Blockly.utils.xml.createElement('next'));
                });
                //----------------------------------------------------------------
                // nextElement = nextElement.appendChild(createControlBlock({ name: 'new_control', ID: 'ctrl ID', disabled: true }));
                // nextElement = nextElement.appendChild(Blockly.utils.xml.createElement('next'));
                // }
                // newXml = initialXml;
                Blockly.Xml.clearWorkspaceAndLoadFromXml(initialXml, brickFactoryWorkspace);

                // // add new "next possible" disabled control
                // // and connect the control to prev. control
                // const newControlBlock = brickFactoryWorkspace.newBlock('control_generic');
                // newControlBlock['nextControl'] = true;
                // block.nextConnection.connect(newControlBlock.previousConnection);
                // newControlBlock.initSvg();
                // newControlBlock.render();

                brickFactoryWorkspace.clearUndo();
                setBrickMode({
                    state: ModeStates.EDIT_STATE,
                    touched: false
                });
            }
            else {
                // New brick
                const xml = Blockly.utils.xml.textToDom(emptyInitialXml);
                // newXml = xml;
                Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, brickFactoryWorkspace);
                brickFactoryWorkspace.clearUndo();
                setBrickMode({
                    state: ModeStates.NEW_STATE,
                    touched: false
                });
            }
            const currentWsXml = Blockly.Xml.workspaceToDom(brickFactoryWorkspace);
            const xmlText = Blockly.Xml.domToText(currentWsXml);
            setSelectedBlockData(null);
            // updateBrickInfo();
            // const xmlText = Blockly.Xml.domToText(newXml);
            // console.log('xmlText', xmlText);
            // setEmptyInitialXml(xmlText);
            setOrigWsXml(xmlText);
        }
        finally {
            Blockly.Events.enable();
        }
    }, [brickFactoryWorkspace, setSelectedBlockData, store.brick]);

    useEffect(() => {
        if (!brickId2Edit) return;

        const disposer = reaction(
            () => store.brick.bricksById.get(brickId2Edit),
            () => {
                console.log('>>> reload brick <<<');
                loadBrickById(brickId2Edit);
            });

        return () => {
            disposer();
        };
    }, [store, brickId2Edit, loadBrickById]);

    // load brick
    useEffect(() => {
        console.log('brickIdToEdit: ', brickId2Edit);
        brickId2EditRef.current = brickId2Edit;
        if (brickFactoryWorkspace) {
            // check if there are unsaved data
            console.log('brickModeRef.current', brickModeRef.current);
            if (brickModeRef.current.touched) {
                setShowUnsavedChangesModal(true);
                return;
            }
            loadBrickById(brickId2Edit);
        }
    }, [brickFactoryWorkspace, brickId2Edit, loadBrickById]);

    // set Blockly change listerner and 'resize' event listener
    useEffect(() => {
        const handleResize = getHandleResize(brickFactoryWorkspace);
        window.addEventListener('resize', handleResize)
        brickFactoryWorkspace?.addChangeListener(blocklyChangeListener);
        // Disable blocks not attached to the 'factory_base' block.
        brickFactoryWorkspace?.addChangeListener(Blockly.Events.disableOrphans);

        return () => {
            window.removeEventListener('resize', handleResize);
            brickFactoryWorkspace?.removeChangeListener(blocklyChangeListener);
            brickFactoryWorkspace?.removeChangeListener(Blockly.Events.disableOrphans);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getHandleResize, blocklyChangeListener, brickFactoryWorkspace]);

    // monitor for changes in the currently loaded brick
    useEffect(() => {
        if (!brickFactoryWorkspace) return;
        if (blocklyEvent?.type === Blockly.Events.VIEWPORT_CHANGE) return;

        const currentWsXml = Blockly.Xml.workspaceToDom(brickFactoryWorkspace);
        const currentWsXmlText = Blockly.Xml.domToText(currentWsXml);
        const currentBrickMode = brickModeRef.current;
        // console.log('curr', currentWsXmlText);
        // console.log('orig', origWsXml);
        if (currentWsXmlText !== origWsXml) {
            // console.log('currentWsXmlText !== origWsXml');
            setBrickMode({ ...currentBrickMode, touched: true });
        }
        else {
            // console.log('currentWsXmlText === origWsXml');
            setBrickMode({ ...currentBrickMode, touched: false });
        }
    }, [blocklyEvent, brickFactoryWorkspace, origWsXml]);

    const getControlsData = (rootBlock) => {
        // iterate through the controls
        let contentsBlock = rootBlock.getInputTargetBlock('INPUTS');
        let controls = [];
        while (contentsBlock) {
            if (!contentsBlock.disabled && !contentsBlock.getInheritedDisabled()) {
                const blockData = JSON.parse(contentsBlock.data);
                console.log('blockData', blockData);

                const controlData = {
                    type: blockData.type,
                    name: blockData.name,
                    ID: blockData.ID,
                };

                if (blockData.id) {
                    controlData._id = blockData.id;
                }
                console.log('controlData', controlData);
                controls.push(controlData);
            }
            contentsBlock = contentsBlock.nextConnection &&
                contentsBlock.nextConnection.targetBlock();
        }
        return controls;
    };

    const saveBrick = useCallback(async () => {
        console.log('Save');

        //const rootBlock = getRootBlock();
        let rootBlock = null;
        const blocks = brickFactoryWorkspace.getTopBlocks(false);
        for (let i = 0, block; !!(block = blocks[i]); i++) {
            if (block.type === 'factory_base') {
                rootBlock = block;
                break;
            }
        }
        if (!rootBlock) { return; }

        const brickData = JSON.parse(rootBlock.data);
        // const brickCaption = rootBlock.getFieldValue('NAME');
        // let brickType = brickCaption.trim().toLowerCase();
        // if (!brickType) {
        //     brickType = UNNAMED;
        // }
        // brickType = brickType.replace(/\W/g, '_').replace(/^(\d)/, '_\\1');



        const brickJson = {
            brick_type: brickData.type, //brickType,
            brick_caption: brickData.name, // brickCaption,
            controls: [...getControlsData(rootBlock)]
        };

        const updatedBrick = await api.brick.update(brickData.id, brickJson);
        if (updatedBrick) {
            console.log('updatedBrick', updatedBrick);
            enqueueSnackbar('Brick was successfully updated', { variant: 'success', persist: false });
        }
        else {
            enqueueSnackbar('Cannot update brick', { variant: 'error', persist: true });
        }

        // mark the brick as saved
        setBrickMode({ ...brickModeRef.current, touched: false });
    }, [brickFactoryWorkspace, api.brick, enqueueSnackbar]);

    const onPropertyChange = useCallback((newBlockData) => {
        const block = brickFactoryWorkspace?.getBlockById(newBlockData?.blockId);
        console.log('onPropertyChange', newBlockData, block);
        if (block) {
            const blockData = newBlockData;
            delete blockData.blockId;
            block['data'] = JSON.stringify(blockData);
            const fieldName = blockData.type === 'brick' ? 'NAME' : 'INPUT_CONTROL_NAME';
            // update name of the brick or control block
            block.setFieldValue(blockData.name, fieldName);
            // trigger a Blockly Event to initiate changes checking logic
            brickFactoryWorkspace.fireChangeListener(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
        }
    }, [brickFactoryWorkspace]);

    const handleCloseModal = useCallback(() => {
        console.log('handleCloseModal')
        setBrickMode({ ...brickModeRef.current, touched: false });
        setShowUnsavedChangesModal(false);
        loadBrickById(brickId2Edit);
    }, [brickId2Edit, loadBrickById]);

    const handleSaveModal = useCallback(() => {
        console.log('handleSaveModal')
        // setBrickMode((mode) => ({ ...mode, touched: false }));
        setShowUnsavedChangesModal(false);
        saveBrick();
        loadBrickById(brickId2Edit);
    }, [brickId2Edit, loadBrickById, saveBrick]);

    return (
        <>
            {/* Brick composing area */}
            {/* <Row className="px-0"> */}
            {/* <Col className={"px-0 " + css(styles.brickFactoryContent)}> */}
            {/* <Box sx={{ flexGrow: 1 }}> */}
            <Grid
                container
                direction="column"
                // justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item >
                    <Box className={css(styles.brickFactoryContent)}>
                        <BlocklyComponent
                            // ref={setBfRef}
                            workspaceSetter={setBrickFactoryWorkspace}
                            readOnly={false}
                            trashcan={true}
                            media={'media/'}
                            horizontalLayout={true}
                            // toolboxPosition={'end'}
                            grid={{
                                spacing: 25,
                                length: 3,
                                colour: '#ccc',
                                snap: true
                            }}
                            move={{
                                scrollbars: true,
                                drag: true,
                                wheel: true
                            }}
                            zoom={{
                                startScale: 0.8,
                                controls: true,
                                wheel: true
                            }}
                            renderer={'zelos'}
                            initialXml={emptyInitialXml}>
                            <Block type="control_generic" />
                        </BlocklyComponent>
                    </Box>
                </Grid>
                <Grid item >
                    <div style={{ display: 'flex', height: '20vh' }}>
                        <GridPropetryEditor
                            blockData={selectedBlockData}
                            onChange={onPropertyChange}
                        />
                    </div>
                </Grid>
            </Grid>
            {/* </Box> */}
            {/* </Row> */}
            {(brickId2Edit !== null && brickMode.touched === true) &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y}
                    text='Save'
                    onClick={() => { saveBrick() }}
                />)
            }
            {/* {brickMode.state === ModeStates.EDIT_STATE &&
                (<FloatingButton
                    x={labelPos.x}
                    y={brickMode.touched === true ? labelPos.y + 35 : labelPos.y}
                    text='New'
                    onClick={() => { loadBrickById(null) }}
                />)
            } */}
            <DialogModal
                title={`Unsaved brick (${originalBrick?.name})`}
                show={showUnsavedChangesModal}
                handleClose={handleCloseModal}
                primaryBtnLabel='Save'
                onPrimary={handleSaveModal}
            >
                <DialogContentText>
                </DialogContentText>
            </DialogModal>

        </>
    );
});
