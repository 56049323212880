import * as Blockly from 'blockly/core';
import { Colors } from 'utils/const';

// browser actions
const ACTION_BROWSER_COLOR = Colors.ACTION_BROWSER_COLOR; //'#138D90';

Blockly.Blocks['browser_commands'] = {
    init: function () {
        this.jsonInit({
            "message0": "%1 %2 %3",
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "COMMAND",
                    "options": [
                        [
                            "Browser URL",
                            "BROWSER_URL"
                        ],
                        [
                            "Browser Keys",
                            "BROWSER_KEYS"
                        ],
                        [
                            "Browser New Window",
                            "BROWSER_NEW_WINDOW"
                        ],
                        [
                            "Browser Switch Window",
                            "BROWSER_SWITCH_WINDOW"
                        ],
                        [
                            "Browser Pause (ms)",
                            "BROWSER_PAUSE"
                        ],
                        [
                            "Browser Debug",
                            "BROWSER_DEBUG"
                        ]
                    ]
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "check": "String"
                }
            ],
            "inputsInline": true,
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": ACTION_BROWSER_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });

        const field = this.getField('COMMAND');
        field.setValidator(this.validate);
    },

    validate: function (action) {
        const actionMap = {
            BROWSER_URL: { type: 'String', value: 'https://' },
            BROWSER_KEYS: { type: 'String', value: 'keys' },
            BROWSER_NEW_WINDOW: { type: 'String', value: 'url/window caption' },
            BROWSER_SWITCH_WINDOW: { type: 'String', value: 'url/window caption' },
            BROWSER_PAUSE: { type: 'Number', value: 1000 },
            BROWSER_DEBUG: null
        };

        const sourceBlock = this.getSourceBlock();

        if (actionMap[action]) {
            if (!sourceBlock.getInput('VALUE')) {
                const newTextInput = sourceBlock.appendValueInput('VALUE');
                newTextInput.setCheck(['Number', 'String']);
            }
            const constInput = sourceBlock.getInput('VALUE');
            constInput.setCheck(['Number', 'String']);
            const shadowXmlText = actionMap[action].type === 'Number' ?
                `<shadow type="math_number"><field name="NUM">${actionMap[action].value}</field></shadow>` :
                `<shadow type="text"><field name="TEXT">${actionMap[action].value}</field></shadow>`
            const shadowXml = Blockly.utils.xml.textToDom(shadowXmlText);
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck(actionMap[action].type);
        }
        else {
            sourceBlock.removeInput('VALUE', true);
        }
    }
};
