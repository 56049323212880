import AppStore from 'stores/app';
import ITreeNode from 'types/treeNode';
import ITreeData from 'types/treeData';
import { NodeType } from 'utils/const';

export default class TreeNode implements ITreeNode {
    id: string;
    text: string;
    node_type: NodeType;
    parent_id: string;
    children: string[];
    data:ITreeData | null;

    constructor(private store: AppStore, node: ITreeNode) {
        this.id = node.id;
        this.text = node.text;
        this.node_type = node.node_type;
        this.parent_id = node.parent_id;
        this.children = node.children;
        this.data = node.data || null;
    }
}