import * as Blockly from 'blockly/core';
import { Colors } from 'utils/const';

// Brick and bricks' controls blocks colors
const BRICK_COLOR = Colors.BRICK_COLOR; //'#444C5C'; //'#444C5C';
const CONTROL_COLOR = Colors.CONTROL_COLOR; //'#4E576A'; //'#4E576A';
const GROUP_COLOR = Colors.GROUP_COLOR; //'#4E576A'; //'#4E576A';

// actions blocks colors
const ACTION_VERIFY_COLOR = Colors.ACTION_VERIFY_COLOR; //'#78A5A3';

const minusImage =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAw' +
    'MC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPS' +
    'JNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMmgxMmMxLjEwNCAw' +
    'IDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K';

Blockly.Blocks['vmt_brick'] = {
    init: function () {
        this.jsonInit({
            // "type": "vmt_brick",
            "message0": "%1 %2 %3 %4",
            "args0": [
                {
                    "type": "field_image",
                    "name": "EXPAND_COLLAPSE",
                    "src": minusImage,
                    "width": 15,
                    "height": 15,
                    "alt": "+",
                    "tooltip": "Collapse block"
                },
                {
                    "type": "field_input",
                    "name": "brick_caption",
                    "text": "brick_caption"
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_statement",
                    "name": "CONTROLS",
                    "check": "Control"
                }
            ],
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": BRICK_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            this.getSourceBlock().setCollapsed(true);
        });
    }
};

const vmtControlJson = {
    // "type": "vmt_control",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "CONTROL_NAME",
            "text": "Control"
        },
        {
            "type": "input_value",
            "name": "ACTION",
            "check": "Action"
        }
    ],
    "previousStatement": "Control",
    "nextStatement": "Control",
    "colour": CONTROL_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['vmt_control'] =
    Blockly.Blocks['vmt_control_last'] = {
        init: function () {
            this.jsonInit(vmtControlJson);
            // this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            //     console.log('>>>EXPAND_COLLAPSE<<<');
            //     this.getSourceBlock().setCollapsed(true);
            // });
        },
        // onchange: function () {
        //     console.log('VMT_CONTROL changed', this);
        // }
    };

Blockly.Blocks['vmt_group'] = {
    init: function () {
        this.jsonInit({
            // "type": "vmt_group",
            "message0": "%1 %2 %3 %4",
            "args0": [
                {
                    "type": "field_image",
                    "name": "EXPAND_COLLAPSE",
                    "src": minusImage,
                    "width": 15,
                    "height": 15,
                    "alt": "+",
                    "tooltip": "Collapse block"
                },
                {
                    "type": "field_input",
                    "name": "GROUP_DESCIPTION",
                    "text": "description"
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_statement",
                    "name": "GROUP_STATEMENT",
                    "check": ["Brick", "null"]
                }
            ],
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": GROUP_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            console.log('>>>EXPAND_COLLAPSE<<<');
            this.getSourceBlock().setCollapsed(true);
        });
    }
};

const expectOpOptions = [
    ["=", "EQ"],
    ["\u2260", "NEQ"],
    ["\u200F<", "LT"],
    ["\u200F\u2264", "LTE"],
    ["\u200F>", "GT"],
    ["\u200F\u2265", "GTE"],
    ["~=", "REGEX"]
];  

Blockly.Blocks['evaluate_brick'] = {
    init: function () {
        this.jsonInit({
            "message0": "%1 %2 %3",
            "args0": [
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "check": ["String", "Boolean", "Number"]
                },
                {
                    "type": "field_dropdown",
                    "name": "OP",
                    "options": expectOpOptions
                },
                {
                    "type": "input_value",
                    "name": "EXPECTED_VALUE",
                    "check": ["Boolean", "String", "Number"]
                }
            ],
            "inputsInline": true,
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": ACTION_VERIFY_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        //this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};

