
import { useRef, useState, useEffect } from 'react';
import Split from 'react-split';
// CSS
import { css } from "aphrodite";
// VMT styles
import styles from 'components/Styles';
import { resizeWindow } from 'utils/misc';


const LEFT_ARROW = '◀';
const RIGHT_ARROW = '▶';

const LOCATIONS = Object.freeze({
    LEFT_HAND_PANEL: 'lhp',
    RIGHT_HAND_PANEL: 'rhp'
});

const STATES = Object.freeze({
    EXPANDED: 'expanded',
    COLLAPSED: 'collapsed'
});

const ATTRIBUTES = Object.freeze({
    STATE: 'state',
    LOCATION: 'location'
});

const expandButtonId = (id) => `expandButton${id}`;

/*
NOTE: this component can deal with ONLY three child components: left center and right
*/
const HorizontalSplit = (props) => {

    const { children } = props;

    const [collapseIndex, setCollapseIndex] = useState(-1);
    const [minSizes, /*setMinSizes*/] = useState([0, 300, 0]);
    const [sizes, setSizes] = useState([15, 60, 25]);

    const leftPanelSizeRef = useRef(null);
    const rightPanelSizeRef = useRef(null);
    const centerPanelSizeRef = useRef(null);
    const sizesRef = [leftPanelSizeRef, centerPanelSizeRef, rightPanelSizeRef];

    const prevLeftPanelSizeRef = useRef(null);
    const prevRightPanelSizeRef = useRef(null);
    const prevCenterPanelSizeRef = useRef(null);
    const prevSizesRef = [prevLeftPanelSizeRef, prevCenterPanelSizeRef, prevRightPanelSizeRef];

    const buttonIdByIndex = { 0: expandButtonId(1), 2: expandButtonId(2) };

    useEffect(() => {
        leftPanelSizeRef.current = sizes[0];
        centerPanelSizeRef.current = sizes[1];
        rightPanelSizeRef.current = sizes[2];
        console.log('sizes', sizes);

        parseInt(sizes[0]) !== 0 && (prevLeftPanelSizeRef.current = sizes[0]);
        parseInt(sizes[1]) !== 0 && (prevCenterPanelSizeRef.current = sizes[1]);
        parseInt(sizes[2]) !== 0 && (prevRightPanelSizeRef.current = sizes[2]);
        console.log('left  ', prevLeftPanelSizeRef.current);
        console.log('center', prevCenterPanelSizeRef.current);
        console.log('right ', prevRightPanelSizeRef.current);
    }, [sizes]);


    const setButtonContent = (button, state) => {
        const currentPanelState = button.getAttribute(ATTRIBUTES.STATE);

        if (state !== currentPanelState) {
            const nextPanelState = currentPanelState === STATES.EXPANDED ? STATES.COLLAPSED : STATES.EXPANDED;
            button.setAttribute(ATTRIBUTES.STATE, nextPanelState);
            button.textContent = button.textContent === LEFT_ARROW ? RIGHT_ARROW : LEFT_ARROW;
        }
    };

    const collapsePanel = (panelIndex) => {
        const newSizes = sizesRef.map(sizeRef => sizeRef.current);
        newSizes[1] += newSizes[panelIndex];
        newSizes[panelIndex] = 0;
        setSizes(newSizes);
        setCollapseIndex(panelIndex);
        const button = document.getElementById(buttonIdByIndex[panelIndex]);
        setButtonContent(button, STATES.COLLAPSED);
    };

    const expandPanel = (panelIndex) => {
        const newSizes = sizesRef.map(sizeRef => sizeRef.current);
        newSizes[panelIndex] = prevSizesRef[panelIndex].current;
        newSizes[1] -= newSizes[panelIndex];
        setSizes(newSizes);
        setCollapseIndex(undefined);
        const button = document.getElementById(buttonIdByIndex[panelIndex]);
        setButtonContent(button, STATES.EXPANDED);
    };

    const collaseExpand = (event) => {
        console.log('collaseExpand', event);
        const button = event.target;
        const currentPanelState = button.getAttribute(ATTRIBUTES.STATE);
        const panelIndex = button.getAttribute(ATTRIBUTES.LOCATION) === LOCATIONS.LEFT_HAND_PANEL ? 0 : 2;
        if (currentPanelState === STATES.EXPANDED) {
            collapsePanel(panelIndex);
        }
        else {
            expandPanel(panelIndex);
        }
        resizeWindow();
    };

    const onSplitDrag = (newSizes) => {
        console.log('onSplitDrag  (new)', newSizes);
        console.log('onSplitDrag (curr)', sizesRef.map(ref => ref.current));
        for (let index = 0; index < sizesRef.length; index++) {
            const itemRef = sizesRef[index];
            if (parseInt(itemRef.current) === 0 && newSizes[index] > 1) { // expand
                const button = document.getElementById(buttonIdByIndex[index]);
                setButtonContent(button, STATES.EXPANDED);
                break;
            }
            else if (itemRef.current > 1 && parseInt(newSizes[index]) === 0) { // collapse
                const button = document.getElementById(buttonIdByIndex[index]);
                setButtonContent(button, STATES.COLLAPSED);
                break;
            }
        }
        setSizes(newSizes);
        resizeWindow();
    }

    const createGutter = (index, direction) => {
        console.log('createGutter', index, direction);
        const gutter = document.createElement('div');
        gutter.setAttribute('id', `gutter${index}`);
        gutter.className = `gutter gutter-${direction}`
        const button = document.createElement('button');
        if (index === 1) {
            button.textContent = LEFT_ARROW;
            button.setAttribute(ATTRIBUTES.STATE, STATES.EXPANDED);
            button.setAttribute(ATTRIBUTES.LOCATION, LOCATIONS.LEFT_HAND_PANEL);
        } else if (index === 2) {
            button.textContent = RIGHT_ARROW;
            button.setAttribute(ATTRIBUTES.STATE, STATES.EXPANDED);
            button.setAttribute(ATTRIBUTES.LOCATION, LOCATIONS.RIGHT_HAND_PANEL);
        }
        button.onclick = collaseExpand;
        button.setAttribute('id', expandButtonId(index));
        button.className = `button gutter-${direction}`;
        gutter.appendChild(button);
        return gutter
    };

    return (
        <Split
            className="split"
            gutter={createGutter}
            onDrag={onSplitDrag}
            minSize={minSizes}
            sizes={sizes}
            collapsed={collapseIndex}
        >
            {children.map((component, index) => <div id={`panel-${index}`} key={index} className={css(styles.appContent)}>{component}</div>)}
        </Split>
    );
}

export default HorizontalSplit;