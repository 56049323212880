// React
import { useRef, useState, useEffect, useCallback } from 'react';

// import { /*autorun,*/ reaction } from 'mobx';

// MUI
// import { loadCSS } from 'fg-loadcss';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon';
// import LinearProgress from '@mui/material/LinearProgress';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';



// CSS
import { css } from "aphrodite";

// Blockly
// import Blockly from 'blockly/core';

// VMT Blockly
// import BlocklyJS from 'blockly/javascript';
// import 'blockly_vmt/generator/generator';

// VMT components
import BrickFactoryComponent from 'components/BrickFactoryComponent';
import BasementComponent from 'components/BasementComponent';
// import HeaderComponent from 'components/HeaderComponent';
import { XMLCodePrettifier, JSCodePrettifier, ExecutionLogCodePrettifier } from 'components/CodePrettifier';
import BrickTreeComponent from 'components/BrickTreeComponent';
import ConstructionTreeComponent from 'components/ConstructionTreeComponent';
import HorizontalSplit from 'components/VApp/HorizontalSplit';
import { VmtTabs, VmtTabPanel } from 'components/VmtTabs';
import { useAppContext } from "app-context";


// VMT styles
import styles from 'components/Styles';

// VMT common
import { TABS, Components, /*URIs,*/ LayoutMode } from 'utils/const'
import { resizeWindow } from 'utils/misc';

import {
    /*apiEndpointStore,*/ blocklyEventStore,
    /*selectedBrickIDsStore,*/ dataBusStore, useStore
} from 'stores';
import StreetsTree from 'components/StreetsTree';

const ME = Components.V_APP.key;

export const VAppLayoutManager = () => {

    const { session: { layout } } = useAppContext();

    const basementWorkspaceRef = useRef(null);

    const [leftPanelActiveTab, setlLeftPanelActiveTab] = useState(TABS.CONSTRUCTION_DIR.name);
    const [playButtonTooltip, setPlayButtonTooltip] = useState('');
    const [playButtonIcon, setPlayButtonIcon] = useState('fa-play')
    const [isExecuting, setIsExecuting] = useState(false);

    const [dataBus, setDataBusContent] = useStore(dataBusStore);
    const [blocklyEvent] = useStore(blocklyEventStore, null);

    useEffect(() => {
        // console.log(blocklyEvent);
        if (blocklyEvent === null ||
            blocklyEvent.blocklyEvent === null ||
            blocklyEvent.blocklyWorkspace === null) return;
        basementWorkspaceRef.current = blocklyEvent.blocklyWorkspace;
    }, [blocklyEvent]);

    useEffect(() => {
        /*fa-play fa-spinner fa-spin fa-pulse */
        if (isExecuting) {
            setPlayButtonTooltip('Running');
            setPlayButtonIcon('fa-spinner fa-pulse');
        }
        else {
            setPlayButtonTooltip('Launch current construction');
            setPlayButtonIcon('fa-play');
        }
    }, [isExecuting]);

    useEffect(() => {
        switch (dataBus?.to) {
            case Components.BRICK_TREE.key:
                // setlLeftPanelActiveTab(TABS.BRICK_DIR.key);
                console.log('>>!! Components.BRICK_TREE.key <<', TABS.BRICK_DIR.name);
                setlLeftPanelActiveTab(TABS.BRICK_DIR.name);
                resizeWindow();
                break;
            case ME:
                if (dataBus.from === Components.LOCAL_RUNNER.key) {
                    switch (dataBus.content.status) {
                        case 'Close':
                            setIsExecuting(false);
                            break;
                        default:
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }, [dataBus]);

    const onExecute = useCallback(() => {
        if (!isExecuting) {
            console.log('RUN');
            setIsExecuting(true);
            // setConsoleText('VMT_CLEAR_EXECUTION_LOG');
            // setConsoleText('[Connecting to Local Runner ...]\n');
            setDataBusContent({
                to: Components.LOCAL_RUNNER.key,
                from: ME,
                content: {
                    command: 'Run',
                    basementWorkspace: basementWorkspaceRef.current
                }
            });
        }
    }, [isExecuting, setDataBusContent]);

    console.log('layout.layoutName', layout.layoutName);

    return (
        <Grid item xs={12}>
            <HorizontalSplit>
                {/* {layout.layoutName === 'construction' ? ( */}
                <VmtTabs
                    activeTabTitle={layout.layoutName === LayoutMode.Construction ? leftPanelActiveTab : TABS.CONSTRUCTION_DIR.name}
                    onSelect={(key) => {
                        resizeWindow();
                        setlLeftPanelActiveTab(key);
                    }}
                >
                    <VmtTabPanel title={TABS.CONSTRUCTION_DIR.name} className={css(styles.scrollingPane)}>
                        <ConstructionTreeComponent />
                    </VmtTabPanel>
                    <VmtTabPanel title={TABS.BRICK_DIR.name} className={css(styles.scrollingPane)} disabled={layout.layoutName !== LayoutMode.Construction ? true : false}>
                        <BrickTreeComponent />
                    </VmtTabPanel>
                </VmtTabs>
                <>
                    {/* {layout.layoutName === LayoutMode.Construction && ( */}
                    < BasementComponent hide={layout.layoutName !== LayoutMode.Construction} />
                    {/* )} */}
                    {/* {layout.layoutName === LayoutMode.Streets && ( */}
                    <div className={css(styles.scrollingPane2)}>
                        < StreetsTree hide={layout.layoutName !== LayoutMode.Streets} />
                    </div>
                    {/* )} */}
                </>
                <VmtTabs
                    onSelect={resizeWindow}
                    activeTabIndex={0}
                >
                    <VmtTabPanel title={TABS.BRICK_FACTORY.name} className={css(styles.nonScrollingPane)}>
                        <BrickFactoryComponent />
                    </VmtTabPanel>
                    <VmtTabPanel title={TABS.CODE_JS.name} className={css(styles.nonScrollingPane)}>
                        <JSCodePrettifier />
                        <Tooltip title={playButtonTooltip}>
                            <Fab
                                style={{
                                    position: 'fixed',
                                    top: '8rem',
                                    right: '3rem',
                                }}
                                size="small"
                                color="secondary"
                                aria-label="run"
                                onClick={onExecute}
                            >
                                <Icon baseClassName="fas" className={playButtonIcon} />
                            </Fab>
                        </Tooltip>
                    </VmtTabPanel>
                    {
                        /* Development env. only */
                        (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                        (<VmtTabPanel title={TABS.XML.name} className={css(styles.nonScrollingPane)}>
                            <XMLCodePrettifier />
                        </VmtTabPanel>)
                    }
                    <VmtTabPanel title={TABS.LOG.name} className={css(styles.nonScrollingPane)}>
                        <ExecutionLogCodePrettifier />
                    </VmtTabPanel>
                </VmtTabs>
            </HorizontalSplit>
        </Grid >
    );
}