import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import { orange } from '@mui/material/colors';

import { NodeType } from 'utils/const';

const cx = (...classNames) => classNames.filter(cn => !!cn).join(' ');
const renderDepthOffset = 10;

export const useTreeItemRender = ({ color: iconColor, ItemIcon, SubItemIcon }) => {
    // const folderIconColor = orange['A100'];
    const NodeIcon = { Expanded: FolderOpenIcon, Collapsed: FolderIcon };
    const EntityIcon = ItemIcon ? ItemIcon : EditRoadOutlinedIcon;
    const SubEntityIcon = SubItemIcon ? SubItemIcon : EntityIcon;
    const SubEntityGroupIcon = { Expanded: FolderOpenIcon, Collapsed: FolderIcon };
    // const SubEntityGroupIcon = { Expanded: VideoLibraryOutlinedIcon, Collapsed: VideoLibraryIcon };


    const renderItemArrow = ({ item, context }) => (
        <div
            className={cx(
                item.isFolder && 'rct-tree-item-arrow-isFolder',
                'rct-tree-item-arrow'
            )}
            {...context.arrowProps}
        >
            {item.isFolder &&
                (context.isExpanded ? (
                    <ArrowDropDownIcon sx={{ color: iconColor, fontSize: 20 }} />
                ) : (
                    <ArrowRightIcon sx={{ color: iconColor, fontSize: 20 }} />
                ))}
        </div>
    );

    // eslint-disable-next-line no-unused-vars
    const renderItemArrowOrig = ({ item, context }) => (
        // Icons from https://blueprintjs.com/docs/#icons
        <div
            className={cx(
                item.isFolder && 'rct-tree-item-arrow-isFolder',
                'rct-tree-item-arrow'
            )}
            {...context.arrowProps}
        >
            {item.isFolder &&
                (context.isExpanded ? (
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 16 16"
                        enableBackground="new 0 0 16 16"
                        xmlSpace="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    className="rct-tree-item-arrow-path"
                                />
                            </g>
                        </g>
                    </svg>
                ) : (
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 16 16"
                        enableBackground="new 0 0 16 16"
                        xmlSpace="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                    className="rct-tree-item-arrow-path"
                                />
                            </g>
                        </g>
                    </svg>
                ))}
        </div>
    );

    const renderItem = ({ item, depth, children, title, context, arrow }) => {
        const InteractiveComponent = context.isRenaming ? 'div' : 'button';
        const type = context.isRenaming ? undefined : 'button';
        // TODO have only root li component create all the classes
        return (
            <li
                {...context.itemContainerWithChildrenProps}
                className={cx(
                    'rct-tree-item-li',
                    item.isFolder && 'rct-tree-item-li-isFolder',
                    context.isSelected && 'rct-tree-item-li-selected',
                    context.isExpanded && 'rct-tree-item-li-expanded',
                    context.isFocused && 'rct-tree-item-li-focused',
                    context.isDraggingOver && 'rct-tree-item-li-dragging-over',
                    context.isSearchMatching && 'rct-tree-item-li-search-match'
                )}
            >
                <div
                    {...context.itemContainerWithoutChildrenProps}
                    style={{ paddingLeft: `${(depth + 1) * renderDepthOffset}px` }}
                    className={cx(
                        'rct-tree-item-title-container',
                        item.isFolder && 'rct-tree-item-title-container-isFolder',
                        context.isSelected && 'rct-tree-item-title-container-selected',
                        context.isExpanded && 'rct-tree-item-title-container-expanded',
                        context.isFocused && 'rct-tree-item-title-container-focused',
                        context.isDraggingOver && 'rct-tree-item-title-container-dragging-over',
                        context.isSearchMatching && 'rct-tree-item-title-container-search-match'
                    )}
                >
                    {arrow}

                    <InteractiveComponent
                        type={type}
                        // style={{color: 'rgb(185, 185, 185)'}}
                        {...context.interactiveElementProps}
                        className={cx(
                            'rct-tree-item-button',
                            // item.isFolder && 'rct-tree-item-button-isFolder',
                            item.node_type === NodeType.Node && 'rct-tree-item-button-isFolder',
                            context.isSelected && 'rct-tree-item-button-selected',
                            context.isExpanded && 'rct-tree-item-button-expanded',
                            context.isFocused && 'rct-tree-item-button-focused',
                            context.isDraggingOver && 'rct-tree-item-button-dragging-over',
                            context.isSearchMatching && 'rct-tree-item-button-search-match'
                        )}
                    >
                        <div className={cx('rtc-tree-icon')}>
                            {/* {(item.isFolder && !context.isExpanded) && (<FolderIcon sx={{ color: folderIconColor }} />)} */}
                            {/* {(item.isFolder && context.isExpanded) && (<FolderOpenIcon sx={{ color: folderIconColor }} />)} */}
                            {/* NodeType.Node */}
                            {(item.node_type === NodeType.Node && !context.isExpanded) && (<NodeIcon.Collapsed sx={{ color: iconColor, fontSize: 20 }} />)}
                            {(item.node_type === NodeType.Node && context.isExpanded) && (<NodeIcon.Expanded sx={{ color: iconColor, fontSize: 20 }} />)}
                            {/* NodeType.Group */}
                            {(item.node_type === NodeType.Group && !context.isExpanded) && (<SubEntityGroupIcon.Collapsed sx={{ color: iconColor, fontSize: 20 }} />)}
                            {(item.node_type === NodeType.Group && context.isExpanded) && (<SubEntityGroupIcon.Expanded sx={{ color: iconColor, fontSize: 20 }} />)}
                            {/* NodeType.Item AND NodeType.NodeItem */}
                            {((item.node_type & NodeType.Item) === NodeType.Item) && (<EntityIcon sx={{ color: iconColor, fontSize: 20 }} />)}
                            {/* NodeType.SubItem AND NodeType.NodeSubItem */}
                            {((item.node_type & NodeType.SubItem) === NodeType.SubItem) && (<SubEntityIcon sx={{ color: iconColor, fontSize: 20 }} />)}
                            {/* {(item.node_type === NodeType.NodeSubItem) && (<SubEntityIcon sx={{ color: iconColor, fontSize: 17 }} />)} */}
                        </div>
                        {title}
                    </InteractiveComponent>
                </div>
                {children}
            </li>
        );
    };

    const renderRenameInput = ({
        item,
        inputProps,
        inputRef,
        submitButtonProps,
        submitButtonRef,
        formProps,
    }) => {
        console.log('renderRenameInput', item.canRename, inputProps, formProps);
        return (
            <form {...formProps} className="rct-tree-item-renaming-form">
                <input
                    {...inputProps}
                    ref={inputRef}
                    className="rct-tree-item-renaming-input"
                    readOnly={!item.canRename}
                />
                <input
                    {...submitButtonProps}
                    ref={submitButtonRef}
                    type="submit"
                    className="rct-tree-item-renaming-submit-button"
                    value="🗸"
                />
            </form>
        );
    };

    return { renderItem, renderItemArrow, renderRenameInput };
}