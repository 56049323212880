// React
import { useEffect } from 'react';
// MobX
import { /*autorun,*/ reaction } from 'mobx';
// VMT
import { useAppContext } from "app-context";
import { useStreetApi } from 'components/StreetsTree/useStreetApi';
import { TreeItemAction } from 'stores/tree';

const useTreeStoresSynchronizer = () => {
    const { store } = useAppContext();
    const { apiRenameStreetTreeItem, apiRemoveReferenceFromStreetTreeItem } = useStreetApi({ treeRef: null });

    useEffect(() => {
        // synch. ConstructionTree => StreetsTree
        const disposerConstrToStreets = reaction(
            () => [...store.constructionTree.lastUpdatedNodeIds],
            async () => {
                // convert Proxy(Array) into the normal Array
                const constrTreeUpdatedNodeIdActs = store.constructionTree.lastUpdatedNodeIds.map(item => { return { id: item.id, action: item.action, node: item.node } });

                // nothing to process
                if (constrTreeUpdatedNodeIdActs.length === 0) return;

                // no action require for 'new' items in the construction tree
                const action = constrTreeUpdatedNodeIdActs[0].action;
                if (action === TreeItemAction.New) return;

                // map construction Id to constuction tree item
                const constrIdConstrTreeItem = constrTreeUpdatedNodeIdActs.reduce(
                    (acc, idAct) => {
                        const constrTreeItem = idAct.node;
                        if (constrTreeItem?.data?.reference_id) {
                            return {
                                ...acc,
                                [constrTreeItem.data.reference_id]: constrTreeItem
                            };
                        }
                        return { ...acc };
                    }, {});

                // no items have touched (eg. move or rename/delete of nodes)
                if (Object.keys(constrIdConstrTreeItem).length === 0 && action !== 'deleted') return;

                const streetTreeItems = store.streetTree.treeItems;
                // console.log('>>> 0. synch. ConstructionTree => StreetsTree <<<', constrTreeUpdatedNodeIdActs);
                // console.log('>>> 1. synch. ConstructionTree => StreetsTree <<<', constrIdConstrTreeItem);
                // console.log('>>> 2. synch. ConstructionTree => StreetsTree <<<', streetTreeItems);

                // check out if the updated construction items have got references to the same construction as street items
                const streetItemIds = Object.keys(streetTreeItems);
                const streetItemsIds2Update = streetItemIds.filter(itemId => {
                    const streetTreeItem = streetTreeItems[itemId];
                    return Object.keys(constrIdConstrTreeItem).includes(streetTreeItem?.data?.reference_id);
                });
                // console.log('>>> 3. synch. ConstructionTree => StreetsTree <<<', streetItemsIds2Update);

                // do nothing if there are no items found 
                if (streetItemsIds2Update.length === 0) return;

                // rename the street's item
                if (action === TreeItemAction.Updated) {
                    streetItemsIds2Update.forEach(async (streetItemId) => {
                        const streetTreeItem = streetTreeItems[streetItemId];
                        const constrTreeItem = constrIdConstrTreeItem[streetTreeItem.data.reference_id];
                        console.log('>>> update street item', streetItemId, constrTreeItem.text);
                        await apiRenameStreetTreeItem(streetItemId, constrTreeItem.text);
                    });
                }

                // rename the street's item to '<no reference>' and remove 'reference_id's
                if (action === TreeItemAction.Deleted) {
                    console.log('action === deleted');
                    streetItemsIds2Update.forEach(async (streetItemId) => {
                        await apiRemoveReferenceFromStreetTreeItem(streetItemId);
                    });
                }
            });

        return () => {
            disposerConstrToStreets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);
};

export default useTreeStoresSynchronizer;