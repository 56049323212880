import { useState, forwardRef, useEffect, useRef } from 'react';
import { ControlledTreeEnvironment, Tree, InteractionMode } from 'react-complex-tree';
import { useAppContext } from 'app-context';
import { useCombinedRefs } from 'utils/misc';
import { useTreeItemRender } from './useTreeItemRender';
import { grey } from '@mui/material/colors';

const saveState = (key, state) => window.localStorage.setItem(key, JSON.stringify(state));
const loadState = (key) => {
    const state = window.localStorage.getItem(key);
    if (state !== null) {
        return JSON.parse(state);
    }
    return [];
};

export const VmtTree = forwardRef(({
    // itemArray,
    treeItems = {
        index: 'root',
        canMove: true,
        isFolder: true,
        canRename: true,
        children: [],
        text: 'Loading ...',
        data: 'Loading ...',
    },
    onPrimaryAction,
    onRenameItem,
    onDeleteKeyPressed,
    onFocusItem,
    onExpandItem,
    onCollapseItem,
    onSelectItems,
    onChangeItemChildren,
    onDrop,
    onNewItemsDrop, // onNewItemsDrop (newItems, targetItem, sourceTreeId)
    defaultInteractionMode = InteractionMode.ClickArrowToExpand,
    canDropAt,
    canDragAndDrop = true,
    canDropOnFolder = true,
    canDropOnNonFolder = false,
    canReorderItems = true,
    canRename = true,
    treeId,
    treeLabel,
    color = grey[500],
    ItemIcon,
    SubItemIcon
}, ref) => {
    console.log(`<VmtTree> ${treeLabel}`);
    console.log('<VmtTree>', treeItems);

    const treeKey = `tree[${treeId}]`

    const { session: { dragAndDropContext, theme } } = useAppContext();

    const [expandedItems, setExpandedItems] = useState(loadState(treeKey));
    const [selectedItems, setSelectedItems] = useState([]);
    const [focusedItem, setFocusedItem] = useState(null);

    const prevSelectedItemRef = useRef(null);
    const timeoutIdRef = useRef(null);
    const treeEnvRef = useRef(null);
    const treeRef = useCombinedRefs(ref);

    const { renderItem, renderItemArrow, renderRenameInput } = useTreeItemRender({ color, ItemIcon, SubItemIcon });

    useEffect(() => {
        console.log('treeItems changed', treeItems);
        if (!treeItems || !treeItems.root) return;
        setFocusedItem(treeItems.root);
        setExpandedItems(e => [...e, treeItems.root.children[0]]);
        setSelectedItems(items => items.filter(itemId => Object.keys(treeItems).includes(itemId)));
    }, [treeItems]);

    // store the tree state to the local storage
    useEffect(() => {
        console.log('Save tree state');
        saveState(treeKey, [...new Set(expandedItems)]);
    }, [expandedItems, treeKey]);

    const focusItem = (item) => {
        console.log("onFocusItem", item);
        setFocusedItem(item);
        onFocusItem && onFocusItem(item);
    };

    const expandItem = (item) => {
        console.log("onExpandItem", item);
        console.log(expandedItems);
        setExpandedItems(e => [...new Set([...e, item.index])]);
        onExpandItem && onExpandItem(item);
    };

    const collapseItem = (item) => {
        console.log("onCollapseItem", item);
        setExpandedItems(currentExpandedItems => currentExpandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index));
        onCollapseItem && onCollapseItem(item);
    };

    const selectItems = (items, treeId) => {
        console.log("onSelectItems", items, treeId);
        setSelectedItems([...items]);
        onSelectItems && onSelectItems(items, treeId);
    };

    const onVmtTreeDrop = (items, target) => {
        console.log('onDrop', treeId, dragAndDropContext, items, target);

        // prevent dropping on 'itself' during the cross tree environments d-n-d ops.
        if (dragAndDropContext.currentTreeId !== dragAndDropContext.sourceTreeId) return;

        const itemIds = items.map(item => item.id);
        let targetItemId;
        let position;
        if (target.targetType === 'item') {
            targetItemId = target.targetItem === 'root' ? treeItems.root.children[0] : target.targetItem;
        }
        else if (target.targetType === 'between-items') {
            if (target.parentItem === 'root') {
                targetItemId = treeItems.root.children[0];
                position = 0;
            }
            else {
                targetItemId = target.parentItem;
                position = target.childIndex;
            }
        }
        onDrop && onDrop(itemIds, targetItemId, position);
    };

    const findTreeItemElement = (element) => {
        /*
            ------------------------------------------------------------------------------------------------------------ 
            DOM structure of an tree item:
            ------------------------------------------------------------------------------------------------------------ 
            <li role="treeitem" aria-selected="false" class="rct-tree-item-li">
                <div data-rct-item-container="true" class="rct-tree-item-title-container" style="padding-left: 30px;">
                    <div class="rct-tree-item-arrow" aria-hidden="true" tabindex="-1">
                        <svg> ... </svg>
                    </div>
                    <button           <=============================================== [TARGET BUTTON ELEMENT]
                        type="button" 
                        draggable="true" 
                        tabindex="-1"   
                        data-rct-item-interactive="true" 
                        data-rct-item-focus="false" 
                        data-rct-item-id="6449d1ccfd530119dc386e1d" 
                        class="rct-tree-item-button"
                    >Tree item text</button>
                </div>
            </li>
            ------------------------------------------------------------------------------------------------------------ 
        */
        try {
            return element.closest('.rct-tree-item-li').querySelector('button');
        } catch {
            return null;
        }
    };

    const getTreeItem = (element) => {
        /*
            ------------------------------------------------------------------------------------------------------------ 
            DOM structure of an tree item:
            ------------------------------------------------------------------------------------------------------------ 
            <li role="treeitem" aria-selected="false" class="rct-tree-item-li">
                <div data-rct-item-container="true" class="rct-tree-item-title-container" style="padding-left: 30px;">
                    <div class="rct-tree-item-arrow" aria-hidden="true" tabindex="-1">
                        <svg> ... </svg>
                    </div>
                    <button           <=============================================== [TARGET BUTTON ELEMENT]
                        type="button" 
                        draggable="true" 
                        tabindex="-1"   
                        data-rct-item-interactive="true" 
                        data-rct-item-focus="false" 
                        data-rct-item-id="6449d1ccfd530119dc386e1d"    <============== [TREE ITEM ID (or .index)]
                        class="rct-tree-item-button"
                    >duckduckgo test 2</button>
                </div>
            </li>
            ------------------------------------------------------------------------------------------------------------ 
        */
        try {
            const targetButtonnEl = findTreeItemElement(element);
            const targetTreeItemId = targetButtonnEl.getAttribute('data-rct-item-id');
            return treeItems[targetTreeItemId];
        } catch {
            return null;
        }
    };

    // Set up the drag and drop context when it happens between trees of different tree environments
    const onVmtEnvDragEnter = (e) => {
        console.log('onVmtEnvDragEnter');
        // 0. We detect the source tree via first onDrag event
        if (dragAndDropContext.sourceTreeId === "") {
            dragAndDropContext.sourceTreeId = treeId;
            dragAndDropContext.dragItems = [...selectedItems.map(id => treeItems[id])];
        }
        else {
            // 1. Set the current tree
            dragAndDropContext.currentTreeId = treeId;
        }
    };

    const onVmtEnvDragLeave = (e) => {
        clearTimeout(timeoutIdRef.current);
        prevSelectedItemRef.current = null;
    };

    const onVmtEnvDrop = (e) => {
        if (dragAndDropContext.currentTreeId !== dragAndDropContext.sourceTreeId &&
            dragAndDropContext.currentTreeId === treeId) {
            // find the target tree item when the drop happened
            const targetTreeItem = getTreeItem(e.target);
            prevSelectedItemRef.current?.classList.remove('d-n-d-hover'); // un-highlight the previous tree item
            prevSelectedItemRef.current = null;
            onNewItemsDrop && onNewItemsDrop(dragAndDropContext.dragItems, targetTreeItem, dragAndDropContext.sourceTreeId);
        }
    };

    const onVmtEnvDragEnd = (e) => {
        // reset the drag and drop context
        dragAndDropContext.sourceTreeId = "";
        dragAndDropContext.currentTreeId = "";
        dragAndDropContext.dragItems = [];

        clearTimeout(timeoutIdRef.current);
        prevSelectedItemRef.current = null;
        treeEnvRef?.current?.abortProgrammaticDrag();
    };

    const onVmtEnvDragOver = (e) => {
        const targetButtonnEl = findTreeItemElement(e.target);
        // long wait over a folder will expand that folder 
        if (targetButtonnEl !== prevSelectedItemRef.current) {
            console.log('clearTimeout');
            clearTimeout(timeoutIdRef.current);
            if (targetButtonnEl !== null) {
                const targetTreeItem = getTreeItem(e.target)
                const targetTreeItemId = targetTreeItem.index;
                // if (targetTreeItem.isFolder) {
                // make the tree item focused
                treeRef?.current?.focusItem(targetTreeItemId);

                timeoutIdRef.current = setTimeout(() => {
                    console.log('Expand', targetTreeItemId);
                    treeRef?.current?.expandItem(targetTreeItemId);
                }, 1000);
            }
            // }
            prevSelectedItemRef.current = targetButtonnEl;
        }

        // highlight the target tree item dirung the cross tree environmnet drag-n-drop ops.
        if (dragAndDropContext.currentTreeId !== dragAndDropContext.sourceTreeId &&
            dragAndDropContext.currentTreeId === treeId) {

            // const targetButtonnEl = findTreeItemElement(e.target);
            if (targetButtonnEl !== null) {
                const targetTreeItem = getTreeItem(e.target)
                const targetTreeItemId = targetTreeItem.index;
                // .rct-tree-item-arrow.rct-tree-item-arrow-isFolder.d-n-d-hover
                // .rct-tree-item-button.d-n-d-hover
                prevSelectedItemRef.current?.classList.remove('d-n-d-hover'); // un-highlight the previous tree item
                // prevSelectedItemRef.current = targetButtonnEl;

                targetButtonnEl.classList.add('d-n-d-hover'); // highlight the current tree item
                // clearTimeout(timeoutIdRef.current);
                // timeoutIdRef.current = setTimeout(() => { prevSelectedItemRef.current?.classList.remove('d-n-d-hover') }, 1000);

                // enable the tree
                treeRef?.current?.focusTree(treeId);
                // make the tree item focused
                treeRef?.current?.focusItem(targetTreeItemId);
            }
        }
    };


    const vmtOnKeyUp = (e) => {
        if (e.code === 'Delete') {
            e.stopPropagation();
            e.preventDefault();
            onDeleteKeyPressed && onDeleteKeyPressed();
        }
    };

    return (
        <div
            onDragEnter={onVmtEnvDragEnter}
            onDragLeave={onVmtEnvDragLeave}
            onDragOver={onVmtEnvDragOver}
            onDrop={onVmtEnvDrop}
            onDragEnd={onVmtEnvDragEnd}
            // start item renaming
            onDoubleClick={(e) => { treeRef?.current?.startRenamingItem(focusedItem.index); }}
            onKeyUp={vmtOnKeyUp}
        >
            {/* <style>{(theme.themeName === 'dark') && (`
                    :root {
                        --rct-color-tree-item-button: rgb(185, 185, 185);
                        --rct-color-tree-item-button-isFolder: whitesmoke;
                    }
                `)}
                {(theme.themeName !== 'dark') && (`
                    :root {
                        --rct-color-tree-item-button: rgb(135, 135, 135);
                        --rct-color-tree-item-button-isFolder: #4e535a;
                    }
                `)}
            </style> */}

            <ControlledTreeEnvironment
                // items and states
                items={treeItems}
                getItemTitle={item => item?.text}
                viewState={{
                    [treeId]: {
                        focusedItem: focusedItem?.index,
                        expandedItems,
                        selectedItems,
                    },
                }}
                defaultInteractionMode={defaultInteractionMode}
                // handlers
                onFocusItem={focusItem}
                onExpandItem={expandItem}
                onCollapseItem={collapseItem}
                onSelectItems={selectItems}
                onDrop={onVmtTreeDrop}
                onChangeItemChildren={onChangeItemChildren}
                onRenameItem={onRenameItem}
                onPrimaryAction={onPrimaryAction}
                // tree env. capabilities
                canDropAt={canDropAt}
                canDragAndDrop={canDragAndDrop}
                canDropOnFolder={canDropOnFolder}
                canDropOnNonFolder={canDropOnNonFolder}
                canReorderItems={canReorderItems}
                canRename={canRename}
                // renders
                renderItem={renderItem}
                renderItemArrow={renderItemArrow}
                renderRenameInput={renderRenameInput}
                // tree env forward reference
                ref={treeEnvRef}
            >
                <div className={theme.themeName === 'dark' ? 'rct-dark' : 'rct-light'} >
                    <Tree treeId={treeId} rootItem="root" treeLabel={treeLabel} ref={treeRef} />
                </div>
            </ControlledTreeEnvironment>
        </div>
    );
});