export const URIs = Object.freeze({
    Default_API: 'https://api.vavanya.com',
    IDP: 'https://myaccount.vavanya.com',
    Fofum: 'https://support.vavanya.com',
    NPM_LocalAgent: 'https://www.npmjs.com/package/@vavanya/local-agent',
    FontAwesome: 'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
});

export const TreeActions = Object.freeze({
    Move: 'move',
    Rename: 'rename',
    Copy: 'move',
    Create: 'create',
    Delete: 'delete'
});

export const CRUD = Object.freeze({
    Create: 'create',
    Read: 'get',
    Update: 'update',
    Delete: 'delete'
});

export const ModeStates = Object.freeze({
    NEW_STATE: 'New',
    EDIT_STATE: 'Edit'
});

export enum Types_old {
    NODE = 'node',
    ITEM = 'item',
    NODE_ITEM = 'node-item'
};

export enum EntityTypes {
    Node = 'node',
    Item = 'item',
    SubItem = 'subitem',
    NodeSubItem = 'node-subitem',
};

/*
     bits| R | G | N | SI| I
    ------------------------------------
         | 0 | 0 | 0 | 0 | 1   = 1 - Item
         | 0 | 0 | 0 | 1 | 0   = 2 - SubItem
         | 0 | 0 | 1 | 0 | 0   = 4 - Node
         | 0 | 0 | 1 | 0 | 1   = 5 - NodeItem
         | 0 | 0 | 1 | 1 | 0   = 6 - NodeSubItem
         | 0 | 1 | 1 | 0 | 0   = 12 - Group

    Where:
        I  - item
        SI - sub-item
        N  - node
        G  - group
        R  - reserved
*/
export enum NodeType {
    Item = 1,
    SubItem = 2,
    Node = 4,
    NodeItem = 5,
    NodeSubItem = 6,
    Group = 12,
};

export const ContextMenuItems = Object.freeze({
    // NewNode: '➕ New Node',
    NewNode: 'New Node',
    // NewGroup: '➕ New Group',
    NewGroup: 'New Group',
    // NewConstruction: '🏗️ New Construction',
    NewConstruction: 'New Construction',
    // NewBrick: '🧱 New Brick',
    NewBrick: 'New Brick',
    // NewStreet: '💈 New Street',
    NewStreet: 'New Street',
    // RenameNode: '🎬 Rename',
    RenameNode: 'Rename',
    JumpToContruction: 'Jump to Construction',
    // DeleteNode: '❌ Delete',
    DeleteNode: 'Delete',
    // NodeDetails: '💬 Details...'
    NodeDetails: 'Details...'
});

export const TABS = Object.freeze({
    BRICK_DIR: {
        key: 'BRICK_DIR',
        name: 'Bricks'
    },
    CONSTRUCTION_DIR: {
        key: 'CONSTRUCTION_DIR',
        name: 'Constructions'
    },
    CODE_JS: {
        key: 'CODE_JS',
        name: 'JavaScript'
    },
    LOG: {
        key: 'LOG',
        name: 'Log'
    },
    XML: {
        key: 'XML',
        name: 'XML'
    },
    BRICK_FACTORY: {
        key: 'BRICK_FACTORY',
        name: 'Brick Factory'
    },
});

export const Components = Object.freeze({
    V_APP: {
        key: 'V_APP',
        name: 'VApp'
    },
    LOCAL_RUNNER: {
        key: 'LOCAL_RUNNER',
        name: 'LocalRunner'
    },
    CODE_EDITOR: {
        key: 'CODE_EDITOR',
        name: 'CodePrettifier'
    },
    BRICK_TREE: {
        key: 'BRICK_TREE',
        name: 'BrickTreeComponent'
    },
    CONSTRUCTION_TREE: {
        key: 'CONSTRUCTION_TREE',
        name: 'ConstructionTreeComponent'
    },
    STREETS_TREE: {
        key: 'STREETS_TREE',
        name: 'StreetsTree'
    },
    BASEMENT: {
        key: 'BASEMENT',
        name: 'BasementComponent'
    },
});

export const InitXmls = Object.freeze({
    WDIO: (testName = 'Test Case') => `
    <xml>
      <block type="base_test" deletable="false" movable="false" x="200" y="100">
      <field name="TEST_NAME">${testName}</field>  
      <statement name="TEST_CONTENT">
          <block type="webdriverio_sync" deletable="false" movable="false"></block>
        </statement>
      </block>
    </xml>`,
    BRICK_FACTORY: () => '<xml><block type="factory_base" deletable="false" movable="false"></block></xml>',
});

export const Colors = Object.freeze({
    // construction block color
    BASE_TEST_COLOR: '#2E476A',
    // WebdriveIO block color
    WDIO_CONFIGURATION_COLOR: '#3E576A',
    // Brick and bricks' controls blocks colors
    BRICK_COLOR: '#444C5C', //'#444C5C',
    CONTROL_COLOR: '#4E576A', //'#4E576A',
    GROUP_COLOR: '#2E476A', //'#4E576A',
    // actions blocks colors
    ACTION_INPUT_COLOR: '#CE5A57',
    ACTION_GETTER_COLOR: '#E1B16A',
    ACTION_VERIFY_COLOR: '#78A5A3',
    // browser actions
    ACTION_BROWSER_COLOR: '#138D90',
});

export const VISUAL_MODES = Object.freeze({
    Construction: {
        value: 'construction',
        display: 'Construction'
    },
    Streets: {
        value: 'streets',
        display: 'Streets'
    },
});

export enum LayoutMode {
    Construction = 'Construction',
    Streets = 'Streets'
}

export enum Themes {
    Dark = 'dark',
    Light = 'light'
}
