import * as Blockly from 'blockly/core';
// import { Colors } from 'utils/const';

// // construction block color
// const BASE_TEST_COLOR = Colors.BASE_TEST_COLOR; //'#2E476A';
// // WebdriveIO block color
// const WDIO_CONFIGURATION_COLOR = Colors.WDIO_CONFIGURATION_COLOR; //'#3E576A';

// // Brick and bricks' controls blocks colors
// const BRICK_COLOR = Colors.BRICK_COLOR; //'#444C5C'; //'#444C5C';
// const CONTROL_COLOR = Colors.CONTROL_COLOR; //'#4E576A'; //'#4E576A';
// const GROUP_COLOR = Colors.GROUP_COLOR; //'#4E576A'; //'#4E576A';

// // actions blocks colors
// const ACTION_INPUT_COLOR = Colors.ACTION_INPUT_COLOR; //'#CE5A57';
// const ACTION_GETTER_COLOR = Colors.ACTION_GETTER_COLOR; //'#E1B16A';
// const ACTION_VERIFY_COLOR = Colors.ACTION_VERIFY_COLOR; //'#78A5A3';
// // browser actions
// const ACTION_BROWSER_COLOR = Colors.ACTION_BROWSER_COLOR; //'#138D90';

Blockly.defineBlocksWithJsonArray([
    {
        "type": "text_concat",
        "message0": "%1 + %2",
        "args0": [
            {
                "type": "input_value",
                "name": "TEXT_A",
                "check": "String"
            },
            {
                "type": "input_value",
                "name": "TEXT_B",
                "check": "String"
            }
        ],
        "output": "String",
        "style": "text_blocks",
        "inputsInline": true,
        "helpUrl": "",
        "tooltip": ""
    },
]);