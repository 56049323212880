// React
import { useState } from 'react';

export const useContextMenu = () => {
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ?
                {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const anchorPosition = (
        () =>
            contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
    )();

    return {
        contextMenu,
        anchorPosition,
        handleContextMenu,
        handleClose
    };
}