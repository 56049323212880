import Brick from 'stores/brick';
import Tree from 'stores/tree';
import Construction from 'stores/construction';

export default class AppStore {
    brick = new Brick(this);
    brickTree = new Tree(this);
    constructionTree = new Tree(this);
    streetTree = new Tree(this, false);
    construction = new Construction(this);
}