import PropTypes from 'prop-types';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DialogModal = ({
    title = 'Dialog',
    handleClose = () => { },
    onPrimary = () => { },
    onSecondary = () => handleClose(),
    primaryBtnLabel = 'OK',
    secondaryBtnLabel = 'Cancel',
    show = false, children }) => {

    // const handlePrimaryBtnClick = () => {
    //     onPrimary();
    // }

    // const handleSecondaryBtnClick = () => {
    //     onSecondary();
    // }

    return (
        <Dialog
            open={show}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers sx={{ padding: 1 }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onSecondary}>{secondaryBtnLabel}</Button>
                <Button variant='contained' autoFocus onClick={onPrimary}>{primaryBtnLabel}</Button>
            </DialogActions>
        </Dialog>
    );
};

DialogModal.propTypes = {
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    onPrimary: PropTypes.func.isRequired,
    onSecondary: PropTypes.func,
    primaryBtnLabel: PropTypes.string,
    secondaryBtnLabel: PropTypes.string,
    show: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
};

export default DialogModal;