import { useEffect, useRef } from 'react';


// TODO: did not manage to force update/render the Blockly component;
// that is workaround which seems to do its job but would need to
// find the root cause of the incorrect behaviour
export const resizeWindow = () => {
    setTimeout(() => { //Start the timer
        window.dispatchEvent(new Event('resize'));
    }, 20);
}

export const useCombinedRefs = (...refs) => {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        })
    }, [refs]);

    return targetRef;
}