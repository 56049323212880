import { NodeType } from 'utils/const';
import { useAppContext } from 'app-context';


export const useStreetApi = ({ treeRef }) => {
    const { api /*, store*/ } = useAppContext();

    const apiCreateStreetTreeEntries = async ({ parentId, nodeType = NodeType.Node, newEntries = [{ text: 'New Street' }], startRenamingItem = true }) => {
        // const treeNodeJson = {
        //     parent_id: parentId,
        //     node_type: nodeType,
        //     text
        //     // no data.reference_id
        // };
        const treeNodeJsons = [];
        newEntries.forEach(entry => {
            const treeJson = {
                parent_id: parentId,
                node_type: nodeType,
                text: entry.text
            };
            if (entry?.data?.reference_id) {
                treeJson.data = { reference_id: entry.data.reference_id };
            }
            treeNodeJsons.push(treeJson);
        });
        // const node = await api.streetTree.createEntry(treeNodeJson);
        console.log('createEntries', treeNodeJsons);
        const nodes = await api.streetTree.createEntries(parentId, treeNodeJsons);
        console.log('New tree item', nodes);
        startRenamingItem && treeRef?.current?.startRenamingItem(nodes[0].id);
        return nodes;
    };

    const apiCreateStreetTreeNode = (parentId) =>
        apiCreateStreetTreeEntries({ parentId, nodeType: NodeType.Node, newEntries: [{ text: 'New Node #1' }] });

    const apiCreateStreetTreeGroup = (parentId) =>
        apiCreateStreetTreeEntries({ parentId, nodeType: NodeType.Group, newEntries: [{ text: 'New Group #1' }] });

    const apiCreateStreetTreeItem = (parentId) =>
        apiCreateStreetTreeEntries({ parentId, nodeType: NodeType.NodeItem, newEntries: [{ text: 'New Street #1' }] });

    const apiCreateStreetTreeSubItems = (parentId, newEntries) =>
        apiCreateStreetTreeEntries({ parentId, nodeType: NodeType.SubItem, newEntries, startRenamingItem: false });

    const apiRenameStreetTreeItem = async (itemId, newItemName) => await api.streetTree.update(itemId, { text: newItemName });

    const apiRemoveReferenceFromStreetTreeItem = async (itemId) => await api.streetTree.update(itemId, { text: '<no reference>', $unset: { data: 1 } });

    const apiStreetTreeItemNodeType = async (itemId, nodeType) => await api.streetTree.update(itemId, { node_type: nodeType });

    return {
        apiCreateStreetTreeNode,
        apiCreateStreetTreeItem,
        apiCreateStreetTreeSubItems,
        apiCreateStreetTreeGroup,
        apiRenameStreetTreeItem,
        apiStreetTreeItemNodeType,
        apiRemoveReferenceFromStreetTreeItem
    }
}