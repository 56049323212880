import { StyleSheet } from "aphrodite";

// CSS in JS
const styles = StyleSheet.create({
    highlightedBlock: {
        fill: "#73a8f3" //"grey"
    },
    nonScrollingPane: {
        // overflow: "auto",
        // overflowX: "scroll",
        // overflowY: "scroll",
        position: "relative",
        minHeight: "80vh",
        height: "calc(100vh - 105px)"
    },

    scrollingPane: {
        overflow: "auto",
        position: "relative",
        minHeight: "80vh",
        height: "calc(100vh - 105px)"
    },

    scrollingPane2: {
        overflow: "auto",
        position: "relative",
        minHeight: "80vh",
        height: "100%"
    },

    modalScrollingPane: {
        // overflowX: "scroll",
        backgroundColor: "white",
        overflowY: "auto",
        maxHeight: "500px"
    },

    modalContainer: {
        // backgroundColor: "#282c34",
        // color: "white"
    },

    brickFactoryContent: {
        // backgroundColor: "#282c34",
        // minHeight: "80vh",
        height: "calc(70vh)",
        // overflow: "auto",
        // display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        // justifyContent: "left"
    },

    appContent: {
        // backgroundColor: "#282c34",
        minHeight: "80vh",
        height: "calc(100vh - 57px)",
        // display: "flex",
        // flexDirection: "row",
        // alignItems: "center",
        // justifyContent: "left"
    },

    appHeader: {
        height: "57px"
    },

    navBar: {
        bgcolor: '#282c34',
        flex: "auto",
        fontFamily: "monospace",
        fontSize: "larger"
    },

    appContentHidden: {
        // display: "none"//,
        minHeight: "80vh",
        height: "calc(100vh - 57px)",
        display: "none",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left"
    },

    appContainer: {
        backgroundColor: "#282c34"
    }      
});

export default styles;