import * as Blockly from 'blockly/core';
import { Colors } from 'utils/const';

// actions blocks colors
const ACTION_VERIFY_COLOR = Colors.ACTION_VERIFY_COLOR; //'#78A5A3';

const expectOpOptions = [
    ["=", "EQ"],
    ["\u2260", "NEQ"],
    ["\u200F<", "LT"],
    ["\u200F\u2264", "LTE"],
    ["\u200F>", "GT"],
    ["\u200F\u2265", "GTE"],
    ["~=", "REGEX"]
];

const expectActionJson = {
    "message0": "%1 %2 %3 %4",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "EXPECT_PROPERTY",
            "options": [
                ['Expect Text', 'TEXT'],
                ['Expect Value', 'VALUE'],
                ['Expect Attribute', 'ATTRIBUTE'],
                ["Expect Displayed", "DISPLAYED"],
                ["Expect In Viewport", "IN_VIEWPORT"],
                ["Expect Enabled", "ENABLED"],
                ["Expect Existing", "EXISTING"],
                ["Expect Focused", "FOCUSED"],
                ["Expect Selected", "SELECTED"]
            ]
        },
        {
            "type": "input_dummy",
            "name": "ATTRIBUTE_NAME_INPUT"
        },
        {
            "type": "field_dropdown",
            "name": "OP",
            "options": expectOpOptions
        },
        {
            "type": "input_value",
            "name": "EXPECTED_VALUE",
            // "check": "Boolean"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_VERIFY_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

function expectActioValidator(action) {
    const sourceBlock = this.getSourceBlock();
    var attrNameInput = sourceBlock.getInput('ATTRIBUTE_NAME_INPUT');
    const expectedValueInput = sourceBlock.getInput('EXPECTED_VALUE');
    const shadowTextXml = Blockly.utils.xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
    const shadowBoolXml = Blockly.utils.xml.textToDom('<shadow type="logic_boolean"><field name="BOOL">TRUE</field></shadow>');


    const type1 = 'String';
    const type2 = ['Boolean', 'String'];
    const actionMap = {
        TEXT: { type: type1, attrField: false },
        VALUE: { type: type1, attrField: false },
        ATTRIBUTE: { type: type1, attrField: true },
        DISPLAYED: { type: type2, attrField: false },
        IN_VIEWPORT: { type: type2, attrField: false },
        ENABLED: { type: type2, attrField: false },
        EXISTING: { type: type2, attrField: false },
        FOCUSED: { type: type2, attrField: false },
        SELECTED: { type: type2, attrField: false },
    };

    // remove 'ATTRIBUTE_NAME' field by default
    if (sourceBlock.getField('ATTRIBUTE_NAME')) {
        attrNameInput.removeField('ATTRIBUTE_NAME');
    }

    // add 'ATTRIBUTE_NAME' field when required
    if (actionMap[action].attrField) {
        if (!sourceBlock.getField('ATTRIBUTE_NAME')) {
            attrNameInput.appendField(new Blockly.FieldTextInput('default'), 'ATTRIBUTE_NAME');
            attrNameInput.init();
        }
    }

    // accept all possible types
    expectedValueInput.setCheck(['Boolean', 'String']);

    const shadowXml = actionMap[action].type === 'String' ? shadowTextXml : shadowBoolXml;
    expectedValueInput.setShadowDom(shadowXml);
    expectedValueInput.setCheck(actionMap[action].type);
}

Blockly.Blocks['expect_action'] = {
    init: function () {
        this.jsonInit({ ...expectActionJson, "output": "Action" });
        this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};

Blockly.Blocks['expect_action_stack'] = {
    init: function () {
        this.jsonInit({ ...expectActionJson, "previousStatement": "Action" });
        this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};

