import * as Blockly from 'blockly/core';
import { Colors } from 'utils/const';

// WebdriveIO block color
const WDIO_CONFIGURATION_COLOR = Colors.WDIO_CONFIGURATION_COLOR; //'#3E576A';

Blockly.defineBlocksWithJsonArray([
    {
        "type": "webdriverio_sync",
        "message0": "Configuration %1 %2 Actions %3 %4",
        "args0": [
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "CONFIG",
                "check": "Config"
            },
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "TEST_CONTENT",
                "check": ["Brick", "null"]
            }
        ],
        "previousStatement": "Technology",
        "colour": WDIO_CONFIGURATION_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
]);