import React from 'react';
import ReactDOM from 'react-dom';

import { SnackbarProvider } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';

import './styles/index.css';
import './styles/react-complex-tree-style-modern.css'
// import './styles/react-complex-tree-style.css'
import AuthWrapper from './AuthWrapper';
import * as serviceWorker from './serviceWorker';

// import { createGlobalStyle } from 'styled-components';

// const Global = createGlobalStyle`
// * {
//   box-sizing: border-box;
// }
// html,
// body,
// #root {
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   background: #191b21;
//   overflow: hidden;
//   font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
//     'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//   font-size: 14px;
//   line-height: 21px;
// }
// html,
// body,
// div,
// a,
// i,
// button,
// select,
// option,
// optgroup,
// hr,
// br {
//   user-select: none;
//   cursor: default;
// }
// `;

// disable console logging for production mode
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    console.log('>>DEVELOPMENT<<');
} else {
    // production code
    console.log = () => { };
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <>
        {/* <Global /> */}
        <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={Zoom}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            )}
        >
            <AuthWrapper />
        </SnackbarProvider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
