import Blockly from 'blockly/core';

export const createBlockData = ({ id = undefined, name, anchor, type = 'generic' }) => {
    return { id: id, name: name, ID: anchor, type: type };
}

export const createBlock = (type, deletable = true, movable = true, disabled = false) => {
    const blockXml = `<block type="${type}" deletable="${deletable}" movable="${movable}" disabled="${disabled}" />`;
    return Blockly.utils.xml.textToDom(blockXml);
};

export const createField = (name, value) => {
    const blockXml = `<field name="${name}">${value}</field>`;
    return Blockly.utils.xml.textToDom(blockXml);
};

export const createStatement = (name) => {
    const blockXml = `<statement name="${name}" />`;
    return Blockly.utils.xml.textToDom(blockXml);
};

// export const createControlBlock = (fieldProps) => {
//     const { _id = null, name, ID, type, disabled = false } = fieldProps;
//     // add control_generic block
//     const block = createBlock('control_generic', true, true, disabled);
//     // add field respresnting control's name
//     let field = createField('INPUT_CONTROL_NAME', name);
//     block.appendChild(field);
//     // // add field respresnting control's identification
//     // field = createField('INPUT_CONTROL_ID', ID);
//     // block.appendChild(field);
//     // add data section
//     let data = Blockly.utils.xml.createElement('data');
//     data.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify(createBlockData({ id: _id, name, ID, type }))));
//     block.appendChild(data);

//     return block;
// };

export const createControlBlock = (fieldProps) => {
    const { id = null, name, anchor, type, disabled = false } = fieldProps;
    // add control_generic block
    const block = createBlock('control_generic', true, true, disabled);
    // add field respresnting control's name
    const field = createField('INPUT_CONTROL_NAME', name);
    block.appendChild(field);
    // add data section
    const data = Blockly.utils.xml.createElement('data');
    data.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify(createBlockData({ id, name, anchor, type }))));
    block.appendChild(data);

    return block;
};
