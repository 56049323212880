import { useContext, useState, createContext, Dispatch } from "react";

import AppApi from "apis/app";
import AppStore from "stores/app";

import { LayoutMode, Themes } from "utils/const";

export const store = new AppStore();
export const api = new AppApi(store);

interface ThemeType {
  themeName: Themes;
  setThemeName: Dispatch<Themes>;
}

interface LayoutType {
  layoutName: string;
  setLayoutName: Dispatch<LayoutMode>;
}

interface UserObject {
  userName: string;
  sessionDuration: number;
}

interface UserInfoType {
  userInfo: UserObject;
  setUserInfo: Dispatch<UserObject>;
}

interface DragAndDropContextType {
  sourceTreeId: string;
  currentTreeId: string;
  dragItems: string[]
}

interface SessionContextType {
  theme: ThemeType;
  layout: LayoutType;
  user: UserInfoType;
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<boolean>;
  loading: boolean;
  setLoading: Dispatch<boolean>;
  dragAndDropContext: DragAndDropContextType;
}

export const CreateSessionContextType = (): SessionContextType => {
  const [themeName, setThemeName] = useState(Themes.Dark);
  const [layoutName, setLayoutName] = useState(LayoutMode.Construction);
  const [userInfo, setUserInfo] = useState({ userName: '', sessionDuration: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const dragAndDropContext = {sourceTreeId: "", currentTreeId: "", dragItems: []};
  
  return {
    theme: {
      themeName,
      setThemeName
    },
    layout: {
      layoutName,
      setLayoutName
    },
    user: {
      userInfo,
      setUserInfo
    },
    isAuthenticated,
    setIsAuthenticated,
    loading,
    setLoading,
    dragAndDropContext,
  } as SessionContextType;
};


interface AppContextType {
  store: AppStore;
  api: AppApi;
  session: SessionContextType;
}

const AppContext = createContext<null | AppContextType>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};

export default AppContext;